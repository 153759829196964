<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <a-table
      :key="table_key"
      :columns="columns"
      :pagination="false"
      :data-source="data_list"
    >
      <div slot="caozuo" slot-scope="record">
        <div v-if="record.status != 0" class="caozuo">
          <div v-if="record.status == 1">已通过</div>
          <div class="jvjue" v-if="record.status == 2">已拒绝</div>
        </div>
        <div v-if="record.status == 0" class="caozuo">
          <a-button
            v-if="record.status == 0"
            @click="reflect_ok(record)"
            size="small"
            type="primary"
            style="margin-right: 30px"
          >
            通过
          </a-button>

          <a-popconfirm
            title="拒绝？"
            ok-text="Yes"
            cancel-text="No"
            @confirm="reflect_can(record)"
            @cancel="reflect_close"
          >
            <a-button size="small" type="danger"> 拒绝 </a-button>
          </a-popconfirm>
        </div>
      </div>
      <a-pagination
        @change="table_page_change"
        :total="total"
        show-less-items
      />
    </a-table>
  </div>
</template>
<script>
import { getAgentRef, setAgentRef } from "@/api";
const columns = [
  {
    dataIndex: "id",
    key: "id",
    align: "center",
    title: "id",
    width: 150,
  },

  {
    title: "用户id",
    dataIndex: "user_id",
    align: "center",
    width: 150,
  },
  {
    title: "体现金额",
    dataIndex: "price",
    align: "center",
  },
  {
    title: "体现时间",
    dataIndex: "created_at",
    align: "center",
  },
  {
    title: "操作",
    align: "center",
    scopedSlots: { customRender: "caozuo" },
  },
];
export default {
  data() {
    return {
      columns,
      table_key: 0,
      data_list: [],
      table_page: 1,
      total: 0,
    };
  },
  methods: {
    reflect_close() {},
    reflect_can(e) {
      setAgentRef({ status: false }, e.id).then((res) => {
        getAgentRef(this.table_page).then((res) => {
          (this.data_list = res.data.data.data), (this.total = res.data.data);
        });
      });
    },
    reflect_ok(e) {
      console.log(e.id);
      setAgentRef({ status: true }, e.id).then((res) => {
        getAgentRef(this.table_page).then((res) => {
          (this.data_list = res.data.data.data), (this.total = res.data.data);
        });
      });
    },
    table_page_change(e) {
      this.table_page = e;
    },
  },
  mounted() {
    getAgentRef(this.table_page).then((res) => {
      (this.data_list = res.data.data.data), (this.total = res.data.data);
    });
  },
};
</script>

<style scoped>
.jvjue {
  color: red;
}
</style>