import Request from "../utls/requeast";



//登陆
export const Login=(param)=>{
    return Request.post('/admin/login',param);
}
export const Logout=()=>{
    return Request.post('/admin/logout');
}
export const init=()=>{
    return Request.post('/admin/user_info');
}




//books
export const getBookList=(page,param)=>{
    return Request.get('/admin/book_da?page='+page+'&&desc='+param);
}

export const get_au_book_list=(page,param)=>{
    return Request.post('/admin/get_au_book_list',param);
}

export const getBooItem=(id)=>{
    return Request.get('/admin/book_da/'+id);
}
export const addBooItem=(param)=>{
    return Request.post('/admin/book_da',param);
}
export const deletBooItem=(id)=>{
    return Request.delete('/admin/book_da/'+id);
}

export const updateBooData=(param,id)=>{

    return Request.put('/admin/book_da/'+id,param);
}


//分类
export const getCates=()=>{
    return Request.get('/admin/cate_da');
}

export const deleteCates=(id)=>{
    return Request.delete('/admin/cate_da/'+id);
}
export const updateCates=(param,id)=>{
    return Request.put('/admin/cate_da/'+id,param);
}

export const addCate=(param)=>{
    return Request.post('/admin/cate_da',param);
}

export const getCateItem=(id)=>{
    return Request.get('/admin/cate_da/'+id);
}





//章

export const getQuarters=(id)=>{
    return Request.get('/admin/quarter_da/'+id);
}
export const getQuartersItem=(id)=>{
    return Request.post('/admin/get_quarters_item',{id:id});
}


export const sendQuarters=(param)=>{
    return Request.post('/admin/quarter_da',param);
}


export const setQuarters=(param,id)=>{
    return Request.put('/admin/quarter_da/'+id,param);
}

export const deleteQuarters=(id)=>{
    return Request.delete('/admin/quarter_da/'+id);
}



//集
export const getEpisode=(id,page)=>{
    return Request.get('/admin/episode_da/'+id+'?page='+page);
}

export const showEpisode=(id)=>{
    return Request.get('/admin/show_episode?id='+id,);
}

export const sendEpisode=(param)=>{
    return Request.post('/admin/episode_da',param);
}


export const setEpisode=(param,id)=>{
    return Request.put('/admin/episode_da/'+id,param);
}

export const deleteEpisode=(id)=>{
    return Request.delete('/admin/episode_da/'+id);
}


//用户


export const getUsers=(page,param)=>{
    return Request.get('/admin/user_da?page='+page+'&&desc='+param);
}

export const showUsers=(id)=>{
    return Request.get('/admin/user_da/'+id,);
}


export const setUsers=(param,id)=>{
    return Request.put('/admin/user_da/'+id,param);
}

//作者
export const getAuthor=(page,param)=>{
    return Request.get('/admin/author_da?page='+page+'&&desc='+param);
}

export const showAuthor=(id)=>{
    return Request.get('/admin/author_da/'+id,);
}


export const setAuthor=(param,id)=>{
    return Request.put('/admin/author_da/'+id,param);
}

export const deleteAuthor=(id)=>{
    return Request.delete('/admin/author_da/'+id);
}

//代理
export const getAgent=(page)=>{
    return Request.get('/admin/agent_da?page='+page);
}

export const showAgent=(id)=>{
    return Request.get('/admin/agent_da/'+id,);
}


export const setAgent=(param,id)=>{
    return Request.put('/admin/agent_da/'+id,param);
}


//代理提现

export const getAgentRef=(page)=>{
    return Request.get('/admin/agent_ref_da?page='+page);
}

export const showAgentRef=(id)=>{
    return Request.get('/admin/agent_ref_da/'+id,);
}


export const setAgentRef=(param,id)=>{
    return Request.put('/admin/agent_ref_da/'+id,param);
}

//作者体现

export const getAuRef=(page)=>{
    return Request.get('/admin/au_ref_da?page='+page);
}

export const showAuRef=(id)=>{
    return Request.get('/admin/au_ref_da/'+id,);
}


export const setAuRef=(param,id)=>{
    return Request.put('/admin/au_ref_da/'+id,param);
}




//评论
export const getCommmentBook=(page,param)=>{
    return Request.post('/admin/get_commment_book?page='+page,param);
}

export const getCommment=(page)=>{
    return Request.get('/admin/comment_da?page='+page);
}


export const setCommment=(param,id)=>{
    return Request.put('/admin/comment_da/'+id,param);
}

export const deleteCommment=(id)=>{
    return Request.delete('/admin/comment_da/'+id);
}


//swiper

export const getSwiper=(page)=>{
    return Request.get('/admin/swiper_da?page='+page);
}

export const showSwiper=(id)=>{
    return Request.get('/admin/swiper_da/'+id,);
}


export const setSwiper=(param,id)=>{
    return Request.put('/admin/swiper_da/'+id,param);
}

export const deleteSwiper=(id)=>{
    return Request.delete('/admin/swiper_da/'+id);
}

export const addSwiper=(param)=>{
    return Request.post('/admin/swiper_da',param);
}

//设置

export const getSetting=()=>{
    return Request.get('/admin/setting_da');
}

export const setSetting=(param,id)=>{
    return Request.put('/admin/setting_da/'+id,param);
}

export const getSettingAd=()=>{
    return Request.get('/admin/getSettingAd');
}

export const setSettingAD=(param)=>{
    return Request.post('/admin/setSettingAD',param);
}

export const getActivity=()=>{
    return Request.get('/admin/getActivity');
}
export const setActivity=(param)=>{
    return Request.post('/admin/setActivity',param);
}

//收音机

export const getRadioList=(page)=>{
    return Request.get('/admin/radio_da?page='+page);
}

export const getRadioItem=(id)=>{
    return Request.get('/admin/radio_da/'+id);
}
export const addRadioItem=(param)=>{
    return Request.post('/admin/radio_da',param);
}
export const deletRadioItem=(id)=>{
    return Request.delete('/admin/radio_da/'+id);
}

export const updateRadioData=(param,id)=>{

    return Request.put('/admin/radio_da/'+id,param);
}


//vip

export const getVipList=(page)=>{
    return Request.get('/admin/vipcate_da?page='+page);
}

export const getVipItem=(id)=>{
    return Request.get('/admin/vipcate_da/'+id);
}
export const addVipItem=(param)=>{
    return Request.post('/admin/vipcate_da',param);
}
export const deleteVipItem=(id)=>{
    return Request.delete('/admin/vipcate_da/'+id);
}

export const updateVipData=(param,id)=>{

    return Request.put('/admin/vipcate_da/'+id,param);
}

//帮助

export const getProblemList=(page)=>{
    return Request.get('/admin/problem_da?page='+page);
}

export const getProblemItem=(id)=>{
    return Request.get('/admin/problem_da/'+id);
}
export const addProblemItem=(param)=>{
    return Request.post('/admin/problem_da',param);
}
export const deletProblemItem=(id)=>{
    return Request.delete('/admin/problem_da/'+id);
}

export const updateProblemData=(param,id)=>{

    return Request.put('/admin/problem_da/'+id,param);
}







export const GetHomeCharts=(param)=>{
    return Request.post('/admin/get_home_detaile',param);
}


export const GetHomeCates=(param)=>{
    return Request.get('/admin/get_cate_data',param);
}

export const GetAuHomeCharts=(param)=>{
    return Request.post('/admin/get_au_home_detaile',param);
}