<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <a-table :key="comment_key" :columns="columns" :pagination="false" :data-source="comment_list">
      <div slot="avatar" slot-scope="text,record">
        <img class="images_poster" :src="text" />
      </div>
      <div slot="caozuo" slot-scope="record">
        <div class="caozuo">
          <a-button
            v-if="record.status == 2"
            @click="send_comment_status(record)"
            size="small"
            type="primary"
            style="margin-right: 30px"
          >
            通过
          </a-button>
          <a-button
            v-if="record.status == 1"
            size="small"
            type="dashed"
            style="margin-right: 30px"
          >
            已通过
          </a-button>
          <a-popconfirm
            title="确定删除？"
            ok-text="Yes"
            cancel-text="No"
            @confirm="delete_comment_item(record.id)"
            @cancel="delete_cancel"
          >
            <a-button size="small" type="danger"> 删除 </a-button>
          </a-popconfirm>
        </div>
      </div>
      <div slot="books" slot-scope="text,record">
        {{ record.books.book_name }}
      </div>

      <a-switch
        slot-scope="text, record"
        slot="status"
        @change="status_change(record)"
        :defaultChecked="text == 1 ? true : false"
        checked-children="开"
        un-checked-children="关"
        default-checked
      />
    </a-table>
    <a-pagination
      @change="Comment_page_change"
      :total="Comment_total"
      show-less-items
    />
  </div>
</template>
<script>
import { getCommment, setCommment, deleteCommment } from "@/api";
const columns = [
  {
    dataIndex: "id",
    key: "id",
    align: "center",
    title: "id",
    width: 150,
  },
  {
    title: "有声读物",
    dataIndex: "books",
    align: "center",
    width: 150,
    scopedSlots: { customRender: "books" },
  },
  {
    title: "用户id",
    dataIndex: "user_id",
    align: "center",
    width: 150,
  },
  {
    title: "头像",
    dataIndex: "avatar",
    align: "center",
    width: 150,
    scopedSlots: { customRender: "avatar" },

  },
  {
    title: "评论内容",
    dataIndex: "comment",
    align: "center",
  },
  {
    title: "操作",
    align: "center",
    scopedSlots: { customRender: "caozuo" },
  },
];
export default {
  data() {
    return {
      columns,
      comment_list: [],
      comment_key:0,
      Comment_total:0,
      Comment_page:1
    };
  },
  methods: {
    Comment_page_change(e){
      this.Comment_page=e
      getCommment(this.Comment_page).then((res) => {
      this.comment_list = res.data.data.data;
      this.Comment_total=res.data.data.total
      this.comment_key++
    });
    },
    delete_comment_item(id) {
      deleteCommment(id).then((res) => {
        getCommment().then((res) => {
          this.comment_list = res.data.data.data;
        });
      });
    },
    delete_cancel() {},
    send_comment_status(e) {
      console.log(e);
      const param = {
        status: 1,
      };
      setCommment(param, e.id).then((res) => {
        getCommment().then((res) => {
          this.comment_list = res.data.data.data;
        });
      });
    },
  },
  mounted() {
    getCommment(this.Comment_page).then((res) => {
      this.comment_list = res.data.data.data;
      this.Comment_total=res.data.data.total
    });
  },
};
</script>

<style>
.images_poster{
  width: 50px;
  height: 50px;
}
</style>