<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div class="top_header">
      <div class="serach_body">
        <a-input-search
          class="input_body"
          placeholder="输入信息"
          enter-button="搜索"
          @search="onSearch"
        />
      </div>
    </div>
    <a-table
      :key="user_key"
      :columns="columns"
      :data-source="user_list"
      :pagination="false"
    >
      <div slot="caozuo" slot-scope="text, record">
        <a-button @click="show_user_edit(record.id)" type="primary" size="small"
          >编辑</a-button
        >
      </div>
      <div slot="vip_time" slot-scope="text, record">
        {{ record.is_vip==0?'-': text}}
      </div>

      <a-switch
        slot-scope="text, record"
        slot="is_vip"
        @change="status_change(record)"
        :defaultChecked="text == 1 ? true : false"
        checked-children="开"
        un-checked-children="关"
        default-checked
      />
    </a-table>
    <a-pagination
      @change="user_page_change"
      :total="user_total"
      show-less-items
    />
    <a-modal
      title="编辑"
      :visible="edit_visible"
      @ok="edit_ok"
      @cancel="edit_cancel"
      :width="820"
    >
      <a-form-model :model="edit_items_body">
        <a-form-model-item label="输入会员时间（天）">
          <a-input style="width: 300px" v-model="edit_items_body.day" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import { getUsers, showUsers, setUsers } from "@/api";
const columns = [
  {
    dataIndex: "id",
    key: "id",
    align: "center",
    title: "id",
  },

  {
    title: "微信名称",
    align: "center",
    dataIndex: "nicName",
  },
  {
    title: "是否会员",
    align: "center",
    dataIndex: "is_vip",
    scopedSlots: { customRender: "is_vip" },
  },
  {
    title: "代理用户",
    align: "center",
    dataIndex: "agent_user",
  },
  {
    title: "vip结束时间",
    align: "center",
    dataIndex: "vip_time",
    scopedSlots: { customRender: "vip_time" },
  },
  {
    title: "操作",
    align: "center",
    scopedSlots: { customRender: "caozuo" },
  },
];

export default {
  data() {
    return {
      columns,
      user_list: [],
      user_page: 1,
      user_total: 0,
      user_key: 0,
      search_desc: "",
      s_value: "",
      edit_visible: false,
      edit_items_body: {
        day: 0,
      },
      edit_id: 0,
    };
  },
  methods: {
    edit_cancel() {
      this.edit_visible = false;
    },
    edit_ok() {
      const timestamp = Math.floor(Date.now() / 1000);
      var params = {
        vip_time: timestamp + 86400 * this.edit_items_body.day,
      };
      setUsers(params, this.edit_id).then((res) => {
        this.edit_visible = false;
        this.edit_id = 0;
        getUsers(this.user_page, this.s_value).then((res) => {
          this.user_list = res.data.data.data;
          this.user_total = res.data.data.total;
          this.user_key = this.user_key + 1;
        });
      });
    },

    show_user_edit(id) {
      this.edit_visible = true;
      this.edit_id = id;
      console.log(id);
    },

    onSearch(value) {
      this.user_page = 1;
      this.s_value = value;

      getUsers(this.user_page, value).then((res) => {
        this.user_list = res.data.data.data;
        this.user_total = res.data.data.total;
      });
    },
    user_page_change(e) {
      this.user_page = e;
      getUsers(this.user_page, this.s_value).then((res) => {
        this.user_list = res.data.data.data;
        this.user_total = res.data.data.total;
        this.user_key = this.user_key + 1;
      });
    },
    status_change(e) {
      const param = {
        is_vip: e.is_vip == 1 ? 0 : 1,
      };
      setUsers(param, e.id).then((res) => {
        console.log(res);
      });
    },
  },
  mounted() {
    getUsers(this.user_page, this.s_value).then((res) => {
      this.user_list = res.data.data.data;
      this.user_total = res.data.data.total;
      this.user_key = this.user_key + 1;
    });
  },
};
</script>
<style scoped>
.input_body {
  margin: 0 auto;
}
.serach_body {
  text-align: center;
  align-self: center;
  width: 300px;
  background-color: white;
  display: flex;
  direction: ltr;
  margin: 0 auto;
}
.top_header {
  background-color: #f0f2f5;
  width: 100%;
  height: 80px;
  direction: rtl;
  display: flex;
}
.caozuo {
  display: flex;
}
</style>