<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div>
      <div class="top_header">
        <a-button class="add_button" @click="show_add_modal" type="primary"
          >添加</a-button
        >
      </div>
      <a-table :columns="columns" :data-source="cate_list" :pagination="false" :key="table_key">
        <div slot-scope="text" slot="poster_image">
            <img class="images_poster" :src="text" />
          </div>
        <div class="caozuo" slot="caozuo" slot-scope="record">
          <a-button
            @click="show_edit_modal(record.id)"
            size="small"
            type="primary"
            style="margin-right: 30px"
          >
          
            编辑
          </a-button>
          <a-popconfirm
            title="确定删除？"
            ok-text="Yes"
            cancel-text="No"
            @confirm="delet_book_item"
            @cancel="delet_cancel"
          >
            <a-button
              @click="show_delet_modal(record.id)"
              size="small"
              type="danger"
            >
              删除
            </a-button>
          </a-popconfirm>
        </div>
  
        <a-switch
          slot-scope="text, record"
          slot="status"
          @change="status_change(record)"
          :defaultChecked="text == 1 ? true : false"
          checked-children="开"
          un-checked-children="关"
          default-checked
        />
      </a-table>
      <a-pagination
      @change="page_change"
      :total="total"
      show-less-items
      v-model="page"
    />
      <a-modal
        title="添加"
        :visible="add_visible"
        @ok="add_cate_ok"
        @cancel="add_cate_ok_can"
        :width="820"
      >
        <a-form-model :model="add_cate_from">
            <a-form-model-item label="输入有声读物id">
                <a-input style="width: 300px" v-model="add_cate_from.book_id" />
              </a-form-model-item>
              <a-form-model-item label="输入图片地址">
                <a-input style="width: 300px" v-model="add_cate_from.poster" />
              </a-form-model-item>
          
        </a-form-model>
      </a-modal>
      <a-modal
        title="编辑"
        :visible="edit_visible"
        @ok="edit_cate_ok"
        @cancel="edit_cate_ok_can"
        :width="820"
      >
        <a-form-model :model="edit_cate_from">
          <a-form-model-item label="输入有声读物id">
            <a-input style="width: 300px" v-model="edit_cate_from.book_id" />
          </a-form-model-item>
          <a-form-model-item label="输入图片地址">
            <a-input style="width: 300px" v-model="edit_cate_from.poster" />
          </a-form-model-item>
          
        </a-form-model>
      </a-modal>
    </div>
  </template>
  <script>
  import {
    getSwiper,
    showSwiper,
    setSwiper,
    deleteSwiper,
    addSwiper

  } from "@/api";
  const columns = [
    {
      dataIndex: "id",
      key: "id",
      align: "center",
      title: "id",
    },
    {
      title: "有声读物id",
      dataIndex: "book_id",
      key: "book_id",
      align: "center",
    },
    {
      title: "略图",
      align: "center",
      dataIndex: "poster",
      scopedSlots: { customRender: "poster_image" },
    },
  
    {
      title: "是否显示",
      align: "center",
      key: "status",
      dataIndex: "status",
      scopedSlots: { customRender: "status" },
    },
    {
      title: "操作",
      align: "center",
      scopedSlots: { customRender: "caozuo" },
    },
  ];
  export default {
    data() {
      return {
        columns,
        cate_item_id:'',
        add_visible:false,
        edit_visible:false,
        cate_list: [],
        add_cate_from:{
          book_id:'',
          poster:'',
          status:1
        },
        edit_cate_from:{
          
        },
        table_key:1,
        total:0,
        page:1
      };
    },
    mounted() {
        getSwiper(this.page).then((res) => {
        this.cate_list = res.data.data.data;
        this.total=res.data.data.total
        this.table_key=this.table_key+1
      });
    },
    methods: {
        page_change(e){
            console.log(e);
            this.page=e
        },
      edit_cate_ok(){
        setSwiper(this.edit_cate_from,this.edit_cate_from.id).then(res=>{
              this.edit_visible=false
              this.edit_cate_from={}
              this.get_cate_list()
          })
      },
      edit_cate_ok_can(){
          this.edit_visible=false
          this.edit_cate_from={}
      },
      add_cate_ok(){
        addSwiper(this.add_cate_from).then(res=>{
              this.get_cate_list()
              this.add_visible=false
          })
      },
      add_cate_ok_can(){
          this.add_visible=false
          this.add_cate_from.title=''
      },
      status_change(e) {
        console.log(e);
        var id=e.id
        var param={
            status:e.status==1?0:1
        }
        setSwiper(param,id).then(res=>{
            console.log(res);
        })
      },
      get_cate_list() {
        getSwiper(this.page).then((res) => {
          this.cate_list = res.data.data.data;
          this.total=res.data.data.total
          this.table_key=this.table_key+1
        });
      },
      show_edit_modal(id) {
          this.edit_visible=true
          showSwiper(id).then(res=>{
              this.edit_cate_from=res.data.data
              
          })
      },
      delet_book_item() {
        deleteSwiper(this.cate_item_id).then(res=>{
              this.get_cate_list()
          })
      },
      delet_cancel() {},
      show_delet_modal(id) {
          this.cate_item_id=id
      },
      show_add_modal() {
          this.add_visible=true
      },
    },
  };
  </script>
  <style scoped>
  .images_poster{
    width: 120px;
    height: 60px;

  }
  .caozuo {
    align-items: center;
    width: 100%;
    display: flex;
  }
  .add_button {
    align-self: center;
    margin-right: 50px;
  }
  .top_header {
    background-color: #f0f2f5;
    width: 100%;
    height: 80px;
    display: flex;
    direction: rtl;
  }
  </style>