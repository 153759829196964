<template>
  <div style="height: 100vh">
    <div class="top_header">
      <a-button class="add_button" @click="show_add_modal" type="primary"
        >添加</a-button
      >
    </div>
    <a-table
      :columns="columns"
      :pagination="false"
      :key="table_key"
      :data-source="book_list"
    >
      <div slot-scope="text" slot="image">
        <img class="images_poster" :src="text" />
      </div>
      <div class="caozuo" slot="caozuo" slot-scope="record">
        <a-button @click="show_edit_modal(record)" size="small" type="primary">
          编辑
        </a-button>
        <!-- <a-popconfirm
          title="确定删除？"
          ok-text="Yes"
          cancel-text="No"
          @confirm="delet_book_item"
          @cancel="delet_cancel"
        >
          <a-button
            @click="show_delet_modal(record.id)"
            size="small"
            type="danger"
          >
            删除
          </a-button>
        </a-popconfirm> -->
      </div>
      <a-switch
        slot-scope="text, record"
        slot="newing_status"
        @change="newing_status_change(record)"
        :defaultChecked="record.newing_status == 1 ? true : false"
        checked-children="开"
        un-checked-children="关"
        default-checked
      />
      <a-switch
        slot-scope="text, record"
        slot="wanjie_status"
        @change="wanjie_status_change(record)"
        :defaultChecked="record.wanjie == 1 ? true : false"
        checked-children="开"
        un-checked-children="关"
        default-checked
      />
      <!-- <a-switch
        slot-scope="text, record"
        slot="status"
        @change="status_change(record)"
        :defaultChecked="record.status == 1 ? true : false"
        checked-children="开"
        un-checked-children="关"
        default-checked
      /> -->
      <div
        @click="show_quarter_modal(record)"
        slot-scope="text, record"
        slot="quearters"
      >
        {{ record.quearters.length }}
      </div>
      <div
        @click="go_cooments_router(record)"
        slot-scope="text, record"
        slot="comment"
      >
        {{ record.comment.length }}
      </div>
    </a-table>
    <a-pagination
      @change="book_page_change"
      :total="book_total"
      show-less-items
      v-model="book_page"
    />
    <a-modal
      title="章数"
      :visible="quarter_modal"
      @ok="quarter_ok"
      @cancel="quarter_cancel"
      :width="820"
    >
      <a-button @click="quarter_add_show" type="primary">添加</a-button>
      <div style="height: 20px"></div>
      <a-list :grid="{ gutter: 16, column: 3 }" :data-source="book_quarters">
        <a-list-item slot="renderItem" slot-scope="item">
          <a-card :title="item.quarter_num + ' ' + item.quarter_name">
            <div class="card_class">
              <div>集数：</div>
              <div>{{ item.episodes.length }}</div>
            </div>
            <div class="card_class">
              <div>总集数：</div>
              <div>{{ item.kisim_sani }}</div>
              <a-button
                class="edit_quater_num"
                @click="edit_quater_num(item.id)"
                type="primary"
                size="small"
                >编辑</a-button
              >
            </div>
            <div class="card_class">
              <div>是否在更新：</div>
              <div>
                <a-switch
                  @change="quarter_newing_status_change(item)"
                  :defaultChecked="item.newing_status == 1 ? true : false"
                  checked-children="开"
                  un-checked-children="关"
                  default-checked
                />
              </div>
            </div>
            <div style="" class="card_class">
              <a-button
                @click="get_episode_lists(item)"
                style="margin-right: 20px"
                size="small"
                type="primary"
              >
                查看分集
              </a-button>
              <a-popconfirm
                title="确定删除？"
                ok-text="Yes"
                cancel-text="No"
                @confirm="delet_quarter_item(item.id)"
                @cancel="delet_quater_cancel"
              >
                <a-button size="small" type="danger"> 删除 </a-button>
              </a-popconfirm>
              <!-- <a-button size="small" type="danger"> 删除 </a-button> -->
            </div>
          </a-card>
        </a-list-item>
      </a-list>
    </a-modal>

    <a-modal
      title="编辑章数"
      :visible="edit_quarter_num_modal"
      @ok="edit_quarter_num_ok"
      @cancel="edit_quarter_num_cancel"
      :width="320"
    >
      <a-form-model-item label="输入总集数">
        <a-input v-model="quarter_item_self.kisim_sani" />
      </a-form-model-item>
    </a-modal>

    <a-drawer
      title="添加章"
      placement="right"
      :closable="false"
      :visible="quarter_add_modal"
      @close="quarter_add_show_close"
      :width="400"
      :maskClosable="false"
    >
      <a-form-model :model="quarter_form">
        <a-form-model-item label="输入标题">
          <a-input v-model="quarter_form.quarter_name" />
        </a-form-model-item>
        <a-form-model-item label="输入章序">
          <a-input v-model="quarter_form.quarter_num" />
        </a-form-model-item>
        <a-form-model-item label="输入总集数">
          <a-input v-model="quarter_form.kisim_sani" />
        </a-form-model-item>
      </a-form-model>

      <div>
        <a-button
          @click="quarter_add_send"
          style="margin-right: 50px"
          type="primary"
          >添加</a-button
        >
        <a-button @click="quarter_add_show_close">取消</a-button>
      </div>
    </a-drawer>
    <a-modal
      title="添加"
      :visible="add_visible"
      @ok="add_modal_quarter_ok"
      @cancel="add_modal_quarter_can"
      :width="820"
    >
      <a-form-model :model="book_item_add">
        <a-form-model-item label="输入标题">
          <a-input style="width: 300px" v-model="book_item_add.book_name" />
        </a-form-model-item>
        <a-form-model-item label="图片地址">
          <a-input v-model="book_item_add.book_poster" />
        </a-form-model-item>
        <a-form-model-item label="正方形图片地址">
          <a-input v-model="book_item_add.book_poster_top" />
        </a-form-model-item>
        
        <a-form-model-item label="分类">
          <!-- <a-input v-model="book_item_from.book_type" /> -->
          <a-select
            default-value="选择分类"
            style="width: 120px"
            @change="add_book_type_change"
          >
            <a-select-option
              v-for="value in book_cates"
              :key="value.id"
              :value="value.id"
            >
              {{ value.title }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="大分类">
          <!-- <a-input v-model="book_item_from.book_type" /> -->
          <a-select
            default-value="选择大分类"
            style="width: 120px"
            @change="add_book_type_da_change"
          >
            <a-select-option key="0" value="0"> ئاۋازلىق </a-select-option>
            <a-select-option key="1" value="1"> خەتلىك </a-select-option>
            <a-select-option key="2" value="2">
              خەتلىك ۋە ئاۋازلىق
            </a-select-option>
            <a-select-option key="3" value="3"> رەسىملىك </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal
      title="编辑"
      :visible="edit_visible"
      @ok="edit_modal_quarter_ok"
      @cancel="edit_modal_quarter_can"
      :width="820"
    >
      <a-form-model :model="book_item_from">
        <a-form-model-item label="输入标题">
          <a-input style="width: 300px" v-model="book_item_from.book_name" />
        </a-form-model-item>
        <a-form-model-item label="图片地址">
          <a-input v-model="book_item_from.book_poster" />
        </a-form-model-item>
        <a-form-model-item label="正方形图片地址">
          <a-input v-model="book_item_from.book_poster_top" />
        </a-form-model-item>
        
        <a-form-model-item label="分类">
          <!-- <a-input v-model="book_item_from.book_type" /> -->
          <a-select
            default-value="选择分类"
            style="width: 120px"
            @change="book_type_change"
            :value="book_item_from.book_type"
          >
            <a-select-option
              v-for="value in book_cates"
              :key="value.id"
              :value="value.id"
            >
              {{ value.title }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="大分类">
          <!-- <a-input v-model="book_item_from.book_type" /> -->
          <a-select
            default-value="选择大分类"
            style="width: 120px"
            @change="edit_book_type_da_change"
            :value="
              book_item_from.book_collection == 0
                ? 'ئاۋازلىق'
                : book_item_from.book_collection == 1
                ? 'خەتلىك'
                : book_item_from.book_collection == 2
                ? ' خەتلىك ۋە ئاۋازلىق'
                : 'رەسىملىك'
            "
          >
            <a-select-option key="0" value="0"> ئاۋازلىق </a-select-option>
            <a-select-option key="1" value="1"> خەتلىك </a-select-option>
            <a-select-option key="2" value="2">
              خەتلىك ۋە ئاۋازلىق
            </a-select-option>
            <a-select-option key="3" value="3"> رەسىملىك </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal
      title="分集"
      :visible="episode_visible"
      @ok="episode_modal_open"
      @cancel="episode_modal_open_can"
      :width="1220"
    >
      <div>
        <a-button @click="show_episode_add_modal()" size="small" type="primary">
          添加
        </a-button>
        <a-table
          :key="table_key1"
          :columns="episode_columns"
          :pagination="false"
          :data-source="episode_list"
        >
          <div class="caozuo" slot="caozuo" slot-scope="record">
            <a-button
              @click="show_episode_edit_modal(record.id)"
              size="small"
              type="primary"
            >
              编辑
            </a-button>
            <a-popconfirm
              title="确定删除？"
              ok-text="Yes"
              cancel-text="No"
              @confirm="delet_episode_item(record.id)"
              @cancel="delet_episode_cancel"
            >
              <a-button size="small" type="danger"> 删除 </a-button>
            </a-popconfirm>
          </div>
          <a-switch
            slot-scope="text, record"
            slot="status"
            @change="episode_status_change(record)"
            :defaultChecked="record.is_vip == 1 ? true : false"
            checked-children="开"
            un-checked-children="关"
          />
        </a-table>
        <a-pagination
          @change="episode_page_change"
          v-model="episode_current"
          :total="episode_total"
          show-less-items
        />
      </div>
    </a-modal>
    <a-drawer
      title="编辑"
      placement="right"
      :closable="false"
      :visible="episode_edit_visible"
      @close="episode_edit_show_close"
      :width="800"
      :maskClosable="false"
    >
      <a-form-model :model="episode_edit_from">
        <a-form-model-item label="输入标题">
          <a-input v-model="episode_edit_from.episode_name" />
        </a-form-model-item>
        
        <a-form-model-item
          v-if="book_item_data.book_collection == 0||book_item_data.book_collection == 2"
          label="输入音频地址"
        >
          <a-input v-model="episode_edit_from.media_adris" />
        </a-form-model-item>
        <a-form-model-item
          v-if="book_item_data.book_collection == 3"
          label="输入长图地址"
        >
          <a-input v-model="episode_edit_from.big_poster" />
        </a-form-model-item>
        <a-form-model-item
          v-if="
            book_item_data.book_collection == 1 ||
            book_item_data.book_collection == 2
          "
          label="输入文字"
        >
          <!-- <a-input type="textarea"  v-model="episode_edit_from.text" /> -->
          <quill-editor
            class="text_editor"
            v-model="episode_edit_from.text"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @change="onEditorChange($event)"
            @ready="onEditorReady($event)"
            :options="editorOption"
            ref="QuillEditor"
          >
          </quill-editor>
        </a-form-model-item>
        <a-form-model-item label="输入价格">
          <a-input v-model="episode_edit_from.buys_price" />
        </a-form-model-item>
      </a-form-model>
      <div>
        <a-button
          @click="episode_edit_send"
          style="margin-right: 50px"
          type="primary"
          >添加</a-button
        >
        <a-button @click="episode_edit_show_close">取消</a-button>
      </div>
    </a-drawer>
    <a-drawer
      title="添加分集"
      placement="right"
      :closable="false"
      :visible="episode_add_visible"
      @close="episode_add_show_close"
      :width="800"
      :maskClosable="false"
    >
      <a-form-model :model="episode_add_from">
        <a-form-model-item label="输入标题">
          <a-input v-model="episode_add_from.episode_name" />
        </a-form-model-item>
        
        <a-form-model-item
          v-if="book_item_data.book_collection == 0||book_item_data.book_collection == 2"
          label="输入音频地址"
        >
          <a-input v-model="episode_add_from.media_adris" />
        </a-form-model-item>
        <a-form-model-item
          v-if="book_item_data.book_collection == 3"
          label="输入长图地址"
        >
          <a-input v-model="episode_add_from.big_poster" />
        </a-form-model-item>
        <a-form-model-item
          v-if="
            book_item_data.book_collection == 1 ||
            book_item_data.book_collection == 2
          "
          label="输入文字"
        >
          <quill-editor
            class="text_editor"
            v-model="episode_add_from.text"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @change="onEditorChange($event)"
            @ready="onEditorReady($event)"
            :options="editorOption"
            ref="QuillEditor"
          >
          </quill-editor>
          <!-- <a-input type="textarea" v-model="episode_add_from.text" /> -->
        </a-form-model-item>
        <a-form-model-item label="输入价格">
          <a-input v-model="episode_add_from.buys_price" />
        </a-form-model-item>
      </a-form-model>
      <div>
        <a-button
          @click="episode_add_send"
          style="margin-right: 50px"
          type="primary"
          >添加</a-button
        >
        <a-button @click="episode_add_show_close">取消</a-button>
      </div>
    </a-drawer>
  </div>
</template>
    <script>
import {
  get_au_book_list,
  updateBooData,
  getCates,
  getQuarters,
  getQuartersItem,
  sendQuarters,
  setQuarters,
  getBooItem,
  deletBooItem,
  addBooItem,
  deleteQuarters,
  getEpisode,
  setEpisode,
  deleteEpisode,
  showEpisode,
  sendEpisode,
} from "@/api";

const columns = [
  {
    dataIndex: "id",
    align: "center",
    title: "id",
  },
  {
    title: "标题",
    dataIndex: "book_name",
    key: "book_name",
    align: "center",
  },
  {
    title: "略图",
    dataIndex: "book_poster",
    key: "book_poster",
    align: "center",
    width: 150,
    scopedSlots: { customRender: "image" },
  },
  {
    title: "分类",
    align: "center",
    dataIndex: "cates.title",
  },
  {
    title: "播放量",
    align: "center",
    dataIndex: "book_look",
  },
  
  {
    title: "评分",
    align: "center",
    dataIndex: "book_score",
  },
  
  {
    title: "章数",
    align: "center",
    dataIndex: "quearters",
    scopedSlots: { customRender: "quearters" },
  },
  
  {
    title: "是否完结",
    align: "center",
    key: "wanjie",
    dataIndex: "wanjie",
    scopedSlots: { customRender: "wanjie_status" },
  },
  
  {
    title: "操作",
    align: "center",
    scopedSlots: { customRender: "caozuo" },
  },
];
const episode_columns = [
  {
    dataIndex: "id",
    key: "id",
    align: "center",
    title: "id",
  },
  {
    dataIndex: "episode_name",
    key: "episode_name",
    align: "center",
    title: "标题",
  },

  {
    dataIndex: "episode_num",
    key: "episode_num",
    align: "center",
    title: "序号",
  },
  {
    dataIndex: "buys_price",

    align: "center",
    title: "价格",
  },
  {
    dataIndex: "is_vip",

    align: "center",
    title: "是否vip",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "操作",
    align: "center",
    scopedSlots: { customRender: "caozuo" },
  },
];

export default {
  data() {
    return {
      edit_quarter_num_modal: false,
      episode_current: 1,
      columns,
      episode_columns,
      book_list: [],
      book_cates: [],
      book_item_id: 0,
      quarter_modal: false,
      book_quarters: [],
      book_item_data: "",
      quarter_add_modal: false,
      edit_visible: false,
      add_visible: false,
      quarter_form: {
        quarter_name: "",
        quarter_num: "",
        book_id: "",
        kisim_sani: "",
      },
      quarter_item_data: "",
      book_item_from: {},
      book_item_add: {
        book_author: "",
        book_name: "",
        id: "",
        book_poster: "",
        book_poster_top: "",
        book_score: 0,
        book_collection: "",
        status:0
      },
      episode_visible: false,
      episode_list: [],
      episode_item_id: "",
      episode_edit_visible: false,
      episode_edit_from: {},
      episode_add_visible: false,
      episode_add_from: {},
      episode_page: 1,
      episode_total: 0,
      book_page: 1,
      book_total: 0,
      table_key: 0,
      table_key1: 0,
      quarter_item_self: {},
    };
  },
  mounted() {
    var params = {
      au_id: this.$userData.au_id,
    };
    console.log('====================================');
    console.log(params);
    console.log('====================================');
    get_au_book_list(this.book_page, params).then((res) => {
      this.book_list = res.data.data.data;
      this.book_total = res.data.data.total;
    });
    getCates().then((res) => {
      this.book_cates = res.data.data.data;
      console.log(res.data.data.data);
    });
  },

  methods: {
    get_au_list() {
      var params = {
        au_id: this.$userData.au_id,
      };
      get_au_book_list(this.book_page, params).then((res) => {
        this.book_list = res.data.data.data;
        this.book_total = res.data.data.total;
        this.table_key = this.table_key + 1;
        // this.table_key = this.table_key + 1;
      });
    },
    edit_quater_num(id) {
      getQuartersItem(id).then((res) => {
        this.quarter_item_self = res.data.data;
      });
      this.edit_quarter_num_modal = true;
    },
    edit_quarter_num_cancel() {
      this.edit_quarter_num_modal = false;
      this.quarter_item_self = {};
    },
    edit_quarter_num_ok() {
      setQuarters(this.quarter_item_self, this.quarter_item_self.id).then(
        (res) => {
          this.edit_quarter_num_modal = false;
          getQuarters(this.book_item_id).then((res) => {
            this.book_quarters = res.data.data;
          });
        }
      );
    },
    go_cooments_router(e) {
      console.log(e);
      let data = {
        id: e.id,
      };
      this.$router.push({
        name: "lokcomments",
        query: data,
      });
      // this.$router.push(`/lokcomments/${e.id}`)
    },
    onSearch(value) {
      this.user_page = 1;

      get_au_book_list(this.book_page, value).then((res) => {
        this.book_list = res.data.data.data;
        this.book_total = res.data.data.total;
        this.table_key = this.table_key + 1;
        // this.table_key = this.table_key + 1;
      });
    },
    edit_book_type_da_change(e) {
      this.book_item_from.book_collection = e;
    },
    add_book_type_da_change(e) {
      console.log(e);
      this.book_item_add.book_collection = e;
    },
    book_page_change(e) {
      this.book_page = e;
      this.get_au_list();
    },
    episode_page_change(e) {
      this.episode_page = e;
      this.get_episode_list();
    },
    get_episode_list() {
      getEpisode(this.episode_item_id, this.episode_page).then((res) => {
        this.episode_list = res.data.data.data;
        this.episode_total = res.data.data.total;
        this.table_key1 = this.table_key1 + 1;
      });
    },
    episode_add_send() {
      console.log(this.episode_add_from);
      sendEpisode(this.episode_add_from).then((res) => {
        console.log(res);
        this.episode_add_visible = false;
        this.get_episode_list();
      });
    },
    show_episode_add_modal() {
      this.episode_add_visible = true;
      var data = {
        quarter_id: this.quarter_item_data.id,
        book_id: this.book_item_data.id,
        buys_price: "",
        episode_name: "",
        episode_num: "",
        is_vip: 1,
        media_adris: "",
      };
      this.episode_add_from = data;
    },
    episode_add_show_close() {
      this.episode_add_visible = false;
    },
    episode_edit_send() {
      setEpisode(this.episode_edit_from, this.episode_edit_from.id).then(() => {
        this.episode_edit_visible = false;
        this.get_episode_list();
      });
    },
    episode_edit_show_close() {
      this.episode_edit_visible = false;
    },

    episode_status_change(e) {
      console.log(e);
      var id = e.id;
      var param = {
        is_vip: e.is_vip == 1 ? 0 : 1,
      };
      setEpisode(param, id).then(() => {
        this.get_episode_list();
      });
    },

    delet_episode_cancel(id) {
      console.log(id);
    },
    delet_episode_item(id) {
      deleteEpisode(id).then(() => {
        this.get_episode_list();
      });
    },
    show_episode_edit_modal(id) {
      showEpisode(id).then((res) => {
        this.episode_edit_from = res.data.data;
        this.episode_edit_visible = true;
      });
    },
    episode_modal_open_can() {
      this.episode_visible = false;
    },
    episode_modal_open() {
      this.episode_visible = false;
    },
    get_episode_lists(item) {
      this.quarter_item_data = item;
      this.episode_item_id = item.id;
      this.get_episode_list();
      this.episode_visible = true;
    },

    delet_quater_cancel() {},
    delet_quarter_item(id) {
      deleteQuarters(id).then((res) => {
        console.log(res);
        getQuarters(this.book_item_id).then((res) => {
          this.book_quarters = res.data.data;
        });
      });
    },
    add_modal_quarter_can() {
      this.book_item_add = {
        book_author: "",
        book_name: "",
        id: "",
        book_poster: "",
        book_score: "",
      };
      this.add_visible = false;
    },
    show_add_modal() {
      this.add_visible = true;
    },
    delet_book_item() {
      console.log(this.book_item_id);
      deletBooItem(this.book_item_id).then((res) => {
        this.get_au_list();
      });
    },
    delet_cancel() {},
    show_delet_modal(id) {
      console.log(id);
      this.book_item_id = id;
    },
    edit_modal_quarter_ok() {
      updateBooData(this.book_item_from, this.book_item_id)
        .then((res) => {
          this.edit_visible = false;
          this.get_au_list();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    add_modal_quarter_ok() {
    this.book_item_add.book_author=this.$userData.au_id
      addBooItem(this.book_item_add)
        .then((res) => {
          this.add_visible = false;
          this.get_au_list();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    edit_modal_quarter_can() {
      this.book_item_from = {};
      this.edit_visible = false;
    },

    show_edit_modal(record) {
      this.book_item_id = record.id;
      this.edit_visible = true;
      getBooItem(record.id).then((res) => {
        this.book_item_from = res.data.data;
      });
    },
    quarter_add_send() {
      if (
        this.quarter_form.book_id &&
        this.quarter_form.quarter_name &&
        this.quarter_form.quarter_num
      ) {
        sendQuarters(this.quarter_form).then((res) => {
          console.log(res);
          this.quarter_add_modal = false;
          this.quarter_form.book_id = "";
          this.quarter_form.quarter_name = "";
          this.quarter_form.quarter_num = "";
          this.quarter_cancel();
          this.get_au_list();
        });
      } else {
        console.log("aaaa");
      }
    },
    quarter_add_show_close() {
      this.quarter_add_modal = false;
    },
    quarter_add_show() {
      this.quarter_add_modal = true;
      this.quarter_form.book_id = this.book_item_data.id;
      console.log(this.book_item_data);
    },
    quarter_newing_status_change(e) {
      console.log(e);
      const param = {
        newing_status: e.newing_status == 1 ? 0 : 0,
      };
      setQuarters(param, e.id).then((res) => {
        console.log(res);
      });
    },
    quarter_cancel() {
      this.quarter_modal = false;
    },
    quarter_ok() {
      this.quarter_modal = false;
    },
    show_quarter_modal(e) {
      this.book_item_data = e;
      this.book_item_id = e.id;
      this.quarter_modal = true;
      getQuarters(e.id).then((res) => {
        this.book_quarters = res.data.data;
      });
    },

    focus(e) {
      this.book_item_id = e.id;
    },
    book_type_change(e) {
      this.book_item_from.book_type = e;
    },
    add_book_type_change(e) {
      this.book_item_add.book_type = e;
    },
    newing_status_change(param) {
      const params = {
        newing_status: param.newing_status == 1 ? 0 : 1,
      };
      updateBooData(params, param.id)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    wanjie_status_change(param) {
      console.log(param.wanjie);
      const params = {
        wanjie: param.wanjie == 0 ? 1 : 0,
      };
      console.log(params);
      updateBooData(params, param.id)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    status_change(param) {
      const params = {
        status: param.status == 1 ? 0 : 1,
      };
      updateBooData(params, param.id)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
  <style scoped>
.serach_body {
  text-align: center;
  align-self: center;
  width: 300px;
  background-color: white;
  display: flex;
  direction: ltr;
  margin: 0 auto;
}
.add_button {
  align-self: center;
  margin-right: 50px;
}

.top_header {
  background-color: #f0f2f5;
  width: 100%;
  height: 80px;
  display: flex;
  direction: rtl;
}
.input_small {
  width: 200px;
}
.caozuo {
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.card_class {
  width: 100%;
  display: flex;
  margin-top: 20px;
}
.images_poster {
  width: 60px;
  height: 80px;
}
.edit_quater_num {
  margin-left: 20px;
}
</style>
    