<template>
  <div>
    <a-descriptions title="小程序设置">
      <a-descriptions-item label="小程序ID">
        {{ setting_data.app_id }}
      </a-descriptions-item>
      <a-descriptions-item label="小程序密钥">
        {{ setting_data.app_key }}
      </a-descriptions-item>
      <a-descriptions-item label="商户号">
        {{ setting_data.mich_id }}
      </a-descriptions-item>
      <a-descriptions-item label="商户号密钥">
        {{ setting_data.mich_key }}
      </a-descriptions-item>
      <a-descriptions-item label="代理分成">
        {{ setting_data.agent_fen }}
      </a-descriptions-item>
      <a-descriptions-item label="订阅模版ID">
        {{ setting_data.moban_id }}
      </a-descriptions-item>
      <a-descriptions-item label="跳转小程序ID">
        {{ setting_data.tiao_id }}
      </a-descriptions-item>
      <a-descriptions-item label="banner广告ID">
        {{ setting_data.banner_id }}
      </a-descriptions-item>
      <a-descriptions-item label="APP下载链接">
        {{ setting_data.app_link }}
      </a-descriptions-item>
    </a-descriptions>
    <div class="button_body">
      <a-button class="bianji_button" @click="show_edit_modal" type="primary">
        编辑
      </a-button>
    </div>

    <a-descriptions title="小程序广告设置">
      <a-descriptions-item label="广告类型">
        {{
          ad_setting_data.type == 1
            ? "播放页面"
            : ad_setting_data.type == 2
            ? "vip页面"
            : ad_setting_data.type == 3
            ? "其他页面"
            : "外部小程序"
        }}
      </a-descriptions-item>
      <a-descriptions-item label="广告图片">
        {{ ad_setting_data.image }}
      </a-descriptions-item>
      <a-descriptions-item label="跳转路径">
        {{ ad_setting_data.path }}
      </a-descriptions-item>
      <a-descriptions-item label="有声读物id">
        {{ ad_setting_data.book_id }}
      </a-descriptions-item>
      <a-descriptions-item label="是否显示">
        {{ ad_setting_data.status == 0 ? "否" : "是" }}
      </a-descriptions-item>
    </a-descriptions>
    <div class="button_body">
      <a-button
        class="bianji_button"
        @click="show_edit_modal_ad"
        type="primary"
      >
        编辑
      </a-button>
    </div>

    <a-descriptions title="小程序活动设置">
      <a-descriptions-item label="是否开启">
        {{ activity_data.status == 1 ? "是" : "否" }}
      </a-descriptions-item>
      <a-descriptions-item label="开始时间">
        {{ s_time}}
      </a-descriptions-item>
      <a-descriptions-item label="结束时间">
        {{ e_time }}
      </a-descriptions-item>
    </a-descriptions>
    <div class="button_body">
      <a-button
        class="bianji_button"
        @click="activity_edit_modal"
        type="primary"
      >
        编辑
      </a-button>
    </div>

    <a-modal
      title="编辑"
      :visible="activity_edit_visible"
      @ok="activity_edit_ok"
      @cancel="activity_edit_cancel"
      :width="820"
    >
      <a-form-model :model="activity_data">
        <a-form-model-item label="广告类型">
          <a-select
            default-value="是否开启"
            style="width: 120px"
            @change="activity_setting_status_change"
          >
            <a-select-option key="0" value="0"> 否 </a-select-option>
            <a-select-option key="1" value="1"> 是 </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="开始时间">
          <a-date-picker
            @ok="start_time_ok"
            :locale="locale"
            show-time
            @change="onChange_start"
          />
        </a-form-model-item>
        <a-form-model-item label="结束时间">
          <a-date-picker :locale="locale" show-time @change="onChange_end" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal
      title="编辑"
      :visible="edit_visible"
      @ok="edit_ok"
      @cancel="edit_cancel"
      :width="820"
    >
      <a-form-model :model="setting_data">
        <a-form-model-item label="小程序ID">
          <a-input style="width: 300px" v-model="setting_data.app_id" />
        </a-form-model-item>
        <a-form-model-item label="小程序密钥">
          <a-input style="width: 300px" v-model="setting_data.app_key" />
        </a-form-model-item>
        <a-form-model-item label="商户ID">
          <a-input style="width: 300px" v-model="setting_data.mich_id" />
        </a-form-model-item>
        <a-form-model-item label="商户密钥">
          <a-input style="width: 300px" v-model="setting_data.mich_key" />
        </a-form-model-item>
        <a-form-model-item label="代理提成">
          <a-input style="width: 300px" v-model="setting_data.agent_fen" />
        </a-form-model-item>
        <a-form-model-item label="模版ID">
          <a-input style="width: 300px" v-model="setting_data.moban_id" />
        </a-form-model-item>
        <a-form-model-item label="跳转小程序ID">
          <a-input style="width: 300px" v-model="setting_data.tiao_id" />
        </a-form-model-item>
        <a-form-model-item label="banner广告ID">
          <a-input style="width: 300px" v-model="setting_data.banner_id" />
        </a-form-model-item>
        <a-form-model-item label="APP下载链接">
          <a-input style="width: 300px" v-model="setting_data.app_link" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal
      title="编辑"
      :visible="ad_edit_visible"
      @ok="ad_edit_ok"
      @cancel="ad_edit_cancel"
      :width="820"
    >
      <a-form-model :model="ad_setting_data">
        <a-form-model-item label="广告类型">
          <a-select
            default-value="选择广告类型"
            style="width: 120px"
            @change="add_setting_ad_change"
          >
            <a-select-option key="1" value="1"> 播放页面 </a-select-option>
            <a-select-option key="2" value="2"> vip页面 </a-select-option>
            <a-select-option key="3" value="3">其他页面</a-select-option>
            <a-select-option key="4" value="4"> 外部小程序 </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="广告图片">
          <a-input style="width: 300px" v-model="ad_setting_data.image" />
        </a-form-model-item>
        <a-form-model-item label="跳转页面">
          <a-input style="width: 300px" v-model="ad_setting_data.path" />
        </a-form-model-item>
        <a-form-model-item label="有声读物id">
          <a-input
            aria-placeholder="跳转路径不是播放页面时可以不填"
            style="width: 300px"
            v-model="ad_setting_data.book_id"
          />
        </a-form-model-item>
        <a-form-model-item label="是否显示">
          <a-select
            default-value="是否显示"
            style="width: 120px"
            @change="status_setting_ad_change"
          >
            <a-select-option key="0" value="0"> 否 </a-select-option>
            <a-select-option key="1" value="1"> 是 </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";

import {
  getSetting,
  setSetting,
  getSettingAd,
  setSettingAD,
  getActivity,
  setActivity,
} from "@/api";
export default {
  data() {
    return {
      locale,
      setting_data: {},
      edit_visible: false,
      ad_setting_data: {},
      ad_edit_visible: false,
      activity_data: {},
      activity_edit_visible: false,
      s_time: "",
      e_time: "",
    };
  },
  methods: {
    start_time_ok(e) {
      console.log(e);
    },
    onChange_end(value, a) {
      this.activity_data.end_time = Date.parse(a) / 1000;
    },
    onChange_start(value, a) {
      this.activity_data.start_time = Date.parse(a) / 1000;
    },
    activity_setting_status_change(e) {
      this.activity_data.status = e;
    },
    activity_edit_ok() {
      setActivity(this.activity_data).then((res) => {
        console.log(res);
        this.activity_edit_visible = false;
      });
    },
    activity_edit_cancel() {
      this.activity_edit_visible = false;
      getActivity().then((res) => {
        this.activity_data = res.data.data;
      const date = new Date(res.data.data.start_time*1000); // 创建日期对象
      const date1 = new Date(res.data.data.end_time*1000); // 创建日期对象
      this.s_time = date.toLocaleString(); // 格式化时间
      this.e_time = date1.toLocaleString(); // 格式化时间
      console.log(date1.toLocaleString());
      });
    },
    activity_edit_modal() {
      this.activity_edit_visible = true;
    },
    status_setting_ad_change(e) {
      this.ad_setting_data.status = e;
    },
    add_setting_ad_change(e) {
      console.log(e);
      this.ad_setting_data.type = e;
    },
    ad_edit_cancel() {
      getSettingAd().then((res) => {
        this.ad_setting_data = res.data.data;
        this.ad_edit_visible = false;
      });
    },
    ad_edit_ok() {
      setSettingAD(this.ad_setting_data, this.ad_setting_data.id).then(
        (res) => {
          this.ad_edit_visible = false;
          getSettingAd().then((res) => {
            this.ad_setting_data = res.data.data;
          });
        }
      );
    },
    show_edit_modal_ad() {
      this.ad_edit_visible = true;
    },
    show_edit_modal() {
      this.edit_visible = true;
    },
    edit_ok() {
      setSetting(this.setting_data, this.setting_data.id).then((res) => {
        this.edit_visible = false;
        getSetting().then((res) => {
          this.setting_data = res.data.data;
        });
      });
    },
    edit_cancel() {
      getSetting().then((res) => {
        this.setting_data = res.data.data;
        this.edit_visible = false;
      });
    },
  },
  mounted() {
    getSetting().then((res) => {
      this.setting_data = res.data.data;
    });
    getSettingAd().then((res) => {
      this.ad_setting_data = res.data.data;
    });
    getActivity().then((res) => {
      this.activity_data = res.data.data;
      const date = new Date(res.data.data.start_time*1000); // 创建日期对象
      const date1 = new Date(res.data.data.end_time*1000); // 创建日期对象
      this.s_time = date.toLocaleString(); // 格式化时间
      this.e_time = date1.toLocaleString(); // 格式化时间
      console.log(date1.toLocaleString());
      
    });
  },
};
</script>
<style scoped>
.button_body {
  width: 100%;
  direction: rtl;
  margin-top: 30px;
  margin-right: 30px;
}
.bianji_button {
}
</style>