<template>
  <a-menu
      :default-selected-keys="['1']"
      :default-open-keys="['2']"
      mode="inline"
      theme="dark"
      :inline-collapsed="collapsed"
    >
      <template v-for="item in list" >
        <a-menu-item
          @click="ClickMenu(item)"
          v-if="!item.children&&(!item.meta||item.meta.requiresAuth==userData.type)"
          :key="item.name"
        >
          <a-icon :type="item.icon" />
          <span>{{ item.title }}</span>
        </a-menu-item>
        <a-sub-menu v-if="item.children&&(!item.meta||item.meta.requiresAuth==userData.type)" :key="item.path" :menu-info="item">
          <span slot="title"
            ><a-icon :type="item.icon" /><span>{{ item.title }}</span></span
          >
          <a-menu-item
            @click="ClickMenu(value)"
            v-for="value in item.children"
            :key="value.name"
          >
            {{ value.title }}
          </a-menu-item>
        </a-sub-menu>
      </template>
    </a-menu>
</template>
  
  <script>
export default {
  data() {
    return {
      collapsed: false,
      list: [
        {
          path: "/home",
          name: "home",
          title: "首页",
          icon:"home",
        },
        {
          path: "/au_book_list",
          name: "au_book_list",
          title: "有声读物列表",
          icon:"ordered-list",
          meta: { requiresAuth: 1 }
        },
        {
          path: "/books",
          name: "books",
          title: "有声读物",
          icon:'ordered-list',
          meta: { requiresAuth: 0},
          children: [
            {
              path: "/book/bookList",
              name: "bookList",
              title: "有声读物列表",
            },
            {
              path: "/book/catelist",
              name: "catelist",
              title: "分类列表",
            },
            {
              path: "/book/swiperlist",
              name: "swiperlist",
              title: "轮播图管理",
            },
          ],
        },
        {
          path: "/radio",
          name: "radio",
          title: "收音机",
          icon:'message',
          meta: { requiresAuth: 0}
        },
        {
          path: "/vipcate",
          name: "vipcate",
          title: "vip分类",
          icon:'message',
          meta: { requiresAuth: 0 }
        },
        {
          path: "/users",
          name: "users",
          title: "用户管理",
          icon:'user',
          meta: { requiresAuth: 0 },
          children: [
            {
              path: "/user/userlist",
              name: "userlist",
              title: "用户列表",
            },
            {
              path: "/user/authorlist",
              name: "authorlist",
              title: "作者列表",
            },
            {
              path: "/user/agentlist",
              name: "agentlist",
              title: "代理列表",
            },
          ],
        },
        {
          path: "/reflects",
          name: "reflects",
          title: "提现管理",
          icon:'pay-circle',
          meta: { requiresAuth: 0 },
          children: [
            {
              path: "/reflects/author_reflects",
              name: "author_reflects",
              title: "作者提现列表",
            },
            {
              path: "/reflects/agent_reflects",
              name: "agent_reflects",
              title: "代理提现列表",
            },
          ],
        },
        {
          path: "/comment",
          name: "comment",
          title: "评论管理",
          icon:'message',
          meta: { requiresAuth: 0}
        },
        {
          path: "/setting",
          name: "setting",
          title: "设置",
          icon:'message',
          meta: { requiresAuth: 0 }
        },
        {
          path: "/problem",
          name: "problem",
          title: "帮助",
          icon:'message',
          meta: { requiresAuth: 0 }
        },
      ],
      userData:{}
    };
  },
  mounted(){
    console.log(this.$userData)
    this.userData=this.$userData
    
    
    console.log('menu');
  },
  methods: {
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
    },
    ClickMenu(item) {
      
        this.$router.push(item.path);
      
      
    },
  },
};
</script>
  <style scoped>
.menu_top {
  display: flex;
}

.element.style {
  width: 100%;
}
</style>
  