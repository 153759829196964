import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../pages/Home.vue";
import User from "../pages/User.vue";
import Main from "../pages/Main.vue";
import Login from "../pages/Login.vue";
import BookList from "../pages/BookList.vue";
import CateList from "../pages/Cates.vue";
import Author from "../pages/Authors.vue";
import Agent from "../pages/Agent.vue";
import AutorReflect from "../pages/AutorReflect.vue";
import AgentReflect from "../pages/UserReflect.vue";
import Comments from "../pages/Comments.vue";
import Swiper from '../pages/SwiperList.vue'
import Setting from '../pages/Setting.vue';
import Radio from '../pages/Radio.vue';
import Problem from '../pages/Problem.vue';
import VipCate from '../pages/VipCate.vue'
import LookComments from '../pages/LookComments.vue'
import AuthorBook from '../pages/AuthorBookList.vue'



Vue.use(VueRouter);

const routes_menu = [
  {
    path: "/",
    component: Main,
    redirect: "/home",

    children: [
      { path: "au_book_list", name: "au_book_list", component: AuthorBook ,meta: { requiresAuth: '1',type:1}},
      { path: "lokcomments", name: "lokcomments", component: LookComments ,meta: { requiresAuth: '0',type:0}},
      { path: "home", name: "home", component: Home },
      { path: "radio", name: "radio", component: Radio ,meta: { requiresAuth: '0',type:0}},
      { path: "problem", name: "problem", component: Problem ,meta: { requiresAuth: '0',type:0}},
      { path: "setting", name: "setting", component: Setting ,meta: { requiresAuth: '0',type:0}},
      { path: "comment", name: "comment", component: Comments ,meta: { requiresAuth: '0',type:0}},
      { path: "vipcate", name: "vipcate", component: VipCate ,meta: { requiresAuth: '0',type:0}},
      { path: "user/userlist", name: "userList", component: User ,meta: { requiresAuth: '0'},type:0},
      { path: "book/bookList", name: "bookList", component: BookList ,meta: { requiresAuth: '0',type:0}},
      { path: "book/catelist", name: "catelist", component: CateList ,meta: { requiresAuth: '0',type:0}},
      { path: "book/swiperlist", name: "swiperlist", component: Swiper ,meta: { requiresAuth: '0',type:0}},
      { path: "user/authorlist", name: "authorlist", component: Author ,meta: { requiresAuth: '0',type:0}},
      { path: "user/agentlist", name: "agentlist", component: Agent ,meta: { requiresAuth: '0',type:0}},
      { path: "reflects/author_reflects", name: "author_reflects", component: AutorReflect ,meta: { requiresAuth: '0',type:0}},
      { path: "reflects/agent_reflects", name: "agent_reflects", component: AgentReflect ,meta: { requiresAuth: '0',type:0}},
    ],
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
];

const router = new VueRouter({
  routes: routes_menu, // (缩写) 相当于 routes: routes
});

export default router;
