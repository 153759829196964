import Vue from "vue";
import App from "./App.vue";
import router from "./router/index.js";
import { init } from "@/api";
// import * as echarts from 'echarts'
// Vue.prototype.$echarts = echarts
import echarts from "echarts";
Vue.prototype.$echarts = echarts;

import "./assents/css/quill.core.css";
import "./assents/css/quill.bubble.css";
import "./assents/css/quill.snow.css";
import VueQuillEditor from "vue-quill-editor";

Vue.use(VueQuillEditor);

import Antd from "ant-design-vue";

import "ant-design-vue/dist/antd.css";

router.beforeEach((to, from, next) => {
  const requiresAuth = to.meta.requiresAuth;
  console.log(requiresAuth, "requiresAuth");
  init()
    .then((res) => {
      console.log(res.data.data);
      Vue.prototype.$userData = res.data.data;
      if (to.name == "login") {
        next({ name: "home" });
      } else {
        if(!requiresAuth){
          console.log('next');
          next();
        }else{
          console.log('next');
          if (requiresAuth==res.data.data.type) {
            next();
          } else {
            console.log('home');
            next({ name: "home" });
          }
        }
        

      }
    })
    .catch(() => {
      if (to.name != "login") {
        next({ name: "login" });
      } else {
        next();
      }
      console.log("====================================");
      console.log("aaa");
      console.log("====================================");
    });
});

Vue.config.productionTip = false;
Vue.use(Antd);
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
