<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div v-if="userData.type == 0" class="boduys">



    <a-row type="row" justify="space-around" align="top">
      <a-col :span="12">
        <div class="home_top_uqur_body">
          <div class="gome_top_uqur_item">
            <div class="gome_top_uqur_item_icon">
              <a-icon
                type="user"
                class="icons"
                :style="{ fontSize: '20px', color: '#fff' }"
              />
            </div>
            <div style="margin-top: 5px">{{ c_data.z_user_counts }}</div>
            <div style="color: #878faf">用户数量</div>
          </div>
          <div class="gome_top_uqur_item">
            <div class="gome_top_uqur_item_icon1">
              <a-icon
                type="bell"
                class="icons"
                :style="{ fontSize: '20px', color: '#fff' }"
              />
            </div>
            <div style="margin-top: 5px">{{ c_data.z_dingye_counts }}</div>
            <div style="color: #878faf">订阅用户</div>
          </div>
          <div class="gome_top_uqur_item">
            <div class="gome_top_uqur_item_icon2">
              <a-icon
                type="solution"
                class="icons"
                :style="{ fontSize: '20px', color: '#fff' }"
              />
            </div>
            <div style="margin-top: 5px">{{ c_data.z_agent_counts }}</div>
            <div style="color: #878faf">代理数量</div>
          </div>
          <div class="gome_top_uqur_item">
            <div class="gome_top_uqur_item_icon3">
              <a-icon
                type="usergroup-delete"
                class="icons"
                :style="{ fontSize: '20px', color: '#fff' }"
              />
            </div>
            <div style="margin-top: 5px">{{ c_data.shi_user_counts }}</div>
            <div style="color: #878faf">用户失流量</div>
          </div>
          <div class="gome_top_uqur_item">
            <div class="gome_top_uqur_item_icon3">
              <a-icon
                type="global"
                class="icons"
                :style="{ fontSize: '20px', color: '#fff' }"
              />
            </div>
            <div style="margin-top: 5px">{{ c_data.hot_user_counts }}</div>
            <div style="color: #878faf">活跃用户</div>
          </div>
          <div @click="show_tiyan" class="gome_top_uqur_item">
            <div class="gome_top_uqur_item_icon">
              <a-icon
                type="api"
                class="icons"
                :style="{ fontSize: '20px', color: '#fff' }"
              />
            </div>
            <div style="margin-top: 5px">{{ c_data.tiyan }}</div>
            <div style="color: #878faf">体验差</div>
          </div>
        </div>
        
      </a-col>
      <a-col :span="12">
        <a-card
        
            title="运用情况"
            :bordered="false"
            style="  background-color: #F2F3F6;"
          >
            <!-- <a slot="extra" href="#">More</a> -->
            <div slot="extra" class="card_top_bottons">
              <a-radio-group
                @change="change_redeo"
                size="small"
                :defaultValue="card_top_menu[0].id"
              >
                <a-radio-button
                  v-for="(item, index) in card_top_menu"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.title }}
                </a-radio-button>
              </a-radio-group>
            </div>
            <div class="card_card">
              <div class="card_item_body">
                <div class="card_top_title">
                  <div>购买会员（元）:{{ vip_price }}</div>
                  <div>订单数量:{{ vip_count }}</div>
                </div>
                <div class="echart-line1">
                  <ChartLine
                    :xData="xData_vip"
                    :key="key + 654631465"
                    :yData="yData_vip"
                  />
                </div>
              </div>
              <div class="card_item_body">
                <div class="card_top_title">
                  <div>购买单片（元）:{{ pay_price }}</div>
                  <div>订单数量:{{ pay_count }}</div>
                </div>
                <div class="echart-line1">
                  <ChartLine
                    :xData="xData_pay"
                    :key="key + 68746313613"
                    :yData="yData_pay"
                  />
                </div>
              </div>
              <div class="card_item_body">
                <div class="card_top_title">
                  <div>新用户:{{ user_counts }}</div>
                  <div>{{ " " }}</div>
                </div>
                <div class="echart-line1">
                  <ChartLine
                    :xData="xData_user"
                    :key="key + 5461321321"
                    :yData="yData_user"
                  />
                </div>
              </div>
            </div>
          </a-card>
      </a-col>

      <a-col :span="12">
        <div class="agent_list">
          <h3 :style="{ marginBottom: '16px' }">
            分类数据
          </h3>
          
    
          <a-list bordered :data-source="cate_list">
            <a-list-item slot="renderItem" slot-scope="item, index">
              <div style="display:flex">
                <div>分类：{{ item.title}} </div>
                <div style="width: 30px;"></div>
                <div>总预览数：{{ item.count }}</div>
              </div>
            </a-list-item>
            
          </a-list>
        </div>
      </a-col>

      
      
      
    </a-row>
    
    


    

    <a-modal
      title="体验差"
      :visible="tiyan_visible"
      @ok="tiyan_ok"
      @cancel="tiyan_cancel"
      :width="820"
    >
      <a-table
        :key="key"
        :columns="columns"
        :data-source="tiyan_list"
        :pagination="false"
      >
      </a-table>
      <a-pagination
        @change="user_page_change"
        :total="user_total"
        show-less-items
      />
    </a-modal>
  </div>
  <div v-else>
    <div class="body_bac">
      <a-row justify="space-around" type="flex" align="top">
        <a-col :span="4">
          <a-card :title="'总收入：' + au_date.au_z_price" style="width: 300px">
            <p>购买会员：{{ au_date.au_vip_price }}</p>
            <p>单片购买：{{ au_date.au_book_price }}</p>
          </a-card>
        </a-col>
        <a-col :span="4">
          <a-card :title="'今日收入：' + au_date.au_to_z_price" style="width: 300px">
            <p>购买会员：{{ au_date.au_to_vip_price }}</p>
            <p>单片购买：{{ au_date.au_to_book_price }}</p>
          </a-card>
        </a-col>
        <a-col :span="4"> </a-col>
      </a-row>
    </div>
  </div>
</template>
<script>
import ChartLine from "../components/EchatsLine.vue";
import { GetHomeCharts, GetAuHomeCharts ,GetHomeCates} from "@/api";
var keys = Date.now();
const columns = [
  {
    dataIndex: "id",
    key: "id",
    align: "center",
    title: "id",
  },

  {
    title: "用户",
    align: "center",
    dataIndex: "nicName",
  },

  {
    title: "类型",
    align: "center",
    dataIndex: "agent_user",
  },
  {
    title: "内容",
    align: "center",
    dataIndex: "vip_time",
  },
];

export default {
  data() {
    return {
      columns,
      size: "default",
      chart_index: 1,
      card_top_menu: [
        {
          id: 1,
          title: "今日",
        },
        {
          id: 2,
          title: "本周",
        },
        {
          id: 3,
          title: "本月",
        },
      ],
      xData_user: [],
      yData_user: [],
      xData_vip: [],
      yData_vip: [],
      xData_pay: [],
      yData_pay: [],
      pay_count: 0,
      pay_price: 0,
      user_counts: 0,
      vip_count: 0,
      vip_price: 0,
      c_data: {},
      key: keys,
      tiyan_visible: false,
      userData: {},
      au_date:{},
      cate_list:[]
    };
  },
  mounted() {

    GetHomeCates({}).then((res)=>{
      console.log(res.data.data,'vate');
      this.cate_list=res.data.data
    })
    this.userData = this.$userData;
    if (this.userData.type == 0) {
      var params = {
        type: this.chart_index,
      };

      GetHomeCharts(params).then((res) => {
        this.pay_count = res.data.data.pay_count;
        this.pay_price = res.data.data.pay_price;
        this.user_counts = res.data.data.user_counts;
        this.vip_count = res.data.data.vip_count;
        this.vip_price = res.data.data.vip_price;
        this.c_data = res.data.data.s_data;
        var pay_order = res.data.data.pay_order;
        var vip_order = res.data.data.vip_order;
        var user = res.data.data.user;
        var xData_user = [];
        var yData_user = [];
        user.map((ele, index) => {
          xData_user[index] = ele.date;
          yData_user[index] = ele.count;
        });
        var xData_vip = [];
        var yData_vip = [];
        vip_order.map((ele, index) => {
          xData_vip[index] = ele.date;
          yData_vip[index] = ele.count;
        });
        var xData_pay = [];
        var yData_pay = [];
        pay_order.map((ele, index) => {
          xData_pay[index] = ele.date;
          yData_pay[index] = ele.count;
        });
        this.xData_pay = xData_pay;
        this.yData_pay = yData_pay;
        this.xData_user = xData_user;
        this.yData_user = yData_user;
        this.xData_vip = xData_vip;
        this.yData_vip = yData_vip;

        this.key = Date.now();
      });
    } else {
      var param = {
        au_id: this.userData.au_id,
      };
      GetAuHomeCharts(param).then(res=>{
        this.au_date=res.data.data

      });
    }

    // const { name, xData, yData } = this;
    // console.log(this.$refs);
    // this.$refs.chart_line_one.initChart(name, xData, yData);
  },
  methods: {
    tiyan_ok() {
      this.tiyan_visible = false;
    },
    tiyan_cancel() {
      this.tiyan_visible = false;
    },
    show_tiyan() {
      this.tiyan_visible = true;
    },
    GetHomeChartsFun() {
      var params = {
        type: this.chart_index,
      };
      GetHomeCharts(params).then((res) => {
        this.pay_count = res.data.data.pay_count;
        this.pay_price = res.data.data.pay_price;
        this.user_counts = res.data.data.user_counts;
        this.vip_count = res.data.data.vip_count;
        this.vip_price = res.data.data.vip_price;
        var pay_order = res.data.data.pay_order;
        var vip_order = res.data.data.vip_order;
        var user = res.data.data.user;
        var xData_user = [];
        var yData_user = [];
        user.map((ele, index) => {
          xData_user[index] = ele.date;
          yData_user[index] = ele.count;
        });
        var xData_vip = [];
        var yData_vip = [];
        vip_order.map((ele, index) => {
          xData_vip[index] = ele.date;
          yData_vip[index] = ele.count;
        });
        var xData_pay = [];
        var yData_pay = [];
        pay_order.map((ele, index) => {
          xData_pay[index] = ele.date;
          yData_pay[index] = ele.count;
        });
        this.xData_pay = xData_pay;
        this.yData_pay = yData_pay;
        this.xData_user = xData_user;
        this.yData_user = yData_user;
        this.xData_vip = xData_vip;
        this.yData_vip = yData_vip;
        this.key = Date.now();
      });
    },
    change_redeo(e) {
      this.chart_index = e.target.value;
      console.log(e.target.value);
      this.GetHomeChartsFun();
    },
  },
  components: {
    ChartLine,
  },
};
</script>
<style >
.agent_list{
  
  background-color: #f2f3f6;
  border-radius: 10px;
  padding: 15px;
  margin: 20px;
}
.echart-line1 {
  width: 100%;
  height: 200px;
}
.card_top_title {
  text-align: center;
}
.card_top_bottons {
  height: 30px;
  display: flex;
}
.card_item_body {
  width: 250px;
  height: 100%;
}
.card_card {
  display: flex;
  justify-content: space-evenly;
}
.chart-container {
  width: 400px;
  height: 400px;
}

.chart {
  width: 400px;
  height: 400px;
}
#main {
  width: 100%;
  height: 200px;
  margin: auto;
  margin: 0 auto;
  margin-top: -60px;
}
.boduys {
  
}
.quxiantu_top {
  background-color: #f2f3f6;
  border-radius: 10px;
  margin: 20px;
  display: flex;
  flex-wrap: wrap;
}
.home_top_uqur_body {
  width: 500px;
  background-color: #f2f3f6;
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
}
.gome_top_uqur_item {
  width: 80px;
  height: 100px;
  text-align: center;
  margin: 30px 40px;
  color: black;
  font-weight: bold;
  font-size: 8px;
}
.gome_top_uqur_item_icon {
  width: 50px;
  height: 50px;
  background-color: #2366b8;
  border-radius: 10px;
  margin: 0 auto;
}
.gome_top_uqur_item_icon1 {
  width: 50px;
  height: 50px;
  background-color: #39abe4;
  border-radius: 10px;
  margin: 0 auto;
}
.gome_top_uqur_item_icon2 {
  width: 50px;
  height: 50px;
  background-color: #f9b50f;
  border-radius: 10px;
  margin: 0 auto;
}
.gome_top_uqur_item_icon3 {
  width: 50px;
  height: 50px;
  background-color: #e32212;
  border-radius: 10px;
  margin: 0 auto;
}
.icons {
  margin-top: 15px;
}
.analysisTask {
  width: 200px;
}
</style>