<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div>
      <div class="top_header">
        <a-button :key="page_key" class="add_button" @click="show_add_modal" type="primary"
          >添加</a-button
        >
      </div>
      <a-table :pagination="false" :columns="columns" :data-source="cate_list">
        <div slot="image" slot-scope="record">
            <img :src="record.poster" class="image_poster"/>
        </div>
        <div class="caozuo" slot="caozuo" slot-scope="record">
          <a-button
            @click="show_edit_modal(record.id)"
            size="small"
            type="primary"
            style="margin-right: 30px"
          >
            编辑
          </a-button>
          <a-popconfirm
            title="确定删除？"
            ok-text="Yes"
            cancel-text="No"
            @confirm="delet_book_item"
            @cancel="delet_cancel"
          >
            <a-button
              @click="show_delet_modal(record.id)"
              size="small"
              type="danger"
            >
              删除
            </a-button>
          </a-popconfirm>
        </div>
  
        <a-switch
          slot-scope="text, record"
          slot="status"
          @change="status_change(record)"
          :defaultChecked="text == 1 ? true : false"
          checked-children="开"
          un-checked-children="关"
          default-checked
        />
      </a-table>
      <a-pagination
      @change="page_change"
      :total="total"
      show-less-items
    />
      <a-modal
        title="添加"
        :visible="add_visible"
        @ok="add_cate_ok"
        @cancel="add_cate_ok_can"
        :width="820"
      >
        <a-form-model :model="add_cate_from">
          <a-form-model-item label="标题">
            <a-input style="width: 300px" v-model="add_cate_from.name" />
          </a-form-model-item>
          <a-form-model-item label="输入答案">
            <a-input style="width: 300px" v-model="add_cate_from.title" />
          </a-form-model-item>
         
          
        </a-form-model>
      </a-modal>
      <a-modal
        title="编辑"
        :visible="edit_visible"
        @ok="edit_cate_ok"
        @cancel="edit_cate_ok_can"
        :width="820"
      >
        <a-form-model :model="edit_cate_from">
            <a-form-model-item label="标题">
                <a-input style="width: 300px" v-model="edit_cate_from.name" />
              </a-form-model-item>
              <a-form-model-item label="输入答案">
                <a-input style="width: 300px" v-model="edit_cate_from.title" />
              </a-form-model-item>
              
          
        </a-form-model>
      </a-modal>
    </div>
  </template>
  <script>
  import {
    getProblemList,
    getProblemItem,
    addProblemItem,
    deletProblemItem,
    updateProblemData,
  } from "@/api";
  const columns = [
    {
      dataIndex: "id",
      key: "id",
      align: "center",
      title: "id",
    },
    {
      title: "标题",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "答案",
      dataIndex: "title",
      key: "title",
      align: "center",
    },
    
  
   
    {
      title: "操作",
      align: "center",
      scopedSlots: { customRender: "caozuo" },
    },
  ];
  export default {
    data() {
      return {
        columns,
        cate_item_id:'',
        add_visible:false,
        edit_visible:false,
        cate_list: [],
        total:0,
        add_cate_from:{
          title:''
        },
        edit_cate_from:{
          
        },
        page_key:0,
        page:1,
      };
      
    },
    mounted() {
        getProblemList(this.page).then((res) => {
        this.cate_list = res.data.data.data;
        this.total=res.data.data.total
      });
    },
    methods: {
        page_change(e){
            console.log(e);
        },
      edit_cate_ok(){
        updateProblemData(this.edit_cate_from,this.edit_cate_from.id).then(res=>{
              this.edit_visible=false
              this.edit_cate_from={}
              this.get_cate_list()
          })
      },
      edit_cate_ok_can(){
          this.edit_visible=false
          this.edit_cate_from={}
      },
      add_cate_ok(){
        addProblemItem(this.add_cate_from).then(res=>{
              this.get_cate_list()
              this.add_visible=false
              this.add_cate_from={}
          })
      },
      add_cate_ok_can(){
          this.add_visible=false
          this.add_cate_from.title=''
      },
      status_change(e) {
        console.log(e);
      },
      get_cate_list() {
        getProblemList().then((res) => {
          this.cate_list = res.data.data.data;
          this.total=res.data.data.total
        });
      },
      show_edit_modal(id) {
          this.edit_visible=true
          getProblemItem(id).then(res=>{
              this.edit_cate_from=res.data.data
              
          })
      },
      delet_book_item() {
        deletProblemItem(this.cate_item_id).then(res=>{
              this.get_cate_list()
          })
      },
      delet_cancel() {},
      show_delet_modal(id) {
          this.cate_item_id=id
      },
      show_add_modal() {
          this.add_visible=true
      },
    },
  };
  </script>
  <style scoped>
  .caozuo {
    align-items: center;
    width: 100%;
    display: flex;
  }
  .add_button {
    align-self: center;
    margin-right: 50px;
  }
  .top_header {
    background-color: #f0f2f5;
    width: 100%;
    height: 80px;
    display: flex;
    direction: rtl;
  }
  .image_poster{
    width: 100px;
    height: 100px;
  }
  </style>