<template>
  <div :id="uuid" :style="style"></div>
</template>
  <script>
import * as echarts from "echarts";
// idGen方法生成一个标识
const idGen = () => {

const num1 = Math.random() * 0.9 + 0.1;
  return new Date().getTime()+Math.floor(num1 * 1000);
};
export default {
  name: "HelloEcharts",
  // 自定义echarts的宽高
  props: {
    height: {
      type: String,
      default: "100%",
    },
    width: {
      type: String,
      default: "100%",
    },
    xData:{
        type:Array,
    },
    yData:{
        type:Array,
    }
  },
  data() {
    return {
      uuid: null, //自定义uuid
      myChart: null,
    };
  },
  created() {
    this.uuid = idGen();
  },
  // style的计算属性，当宽高改变的时候，style发生相应改变
  computed: {
    style() {
      return {
        height: this.height,
        width: this.width,
      };
    },
  },
  mounted() {
    this.myChart = echarts.init(document.getElementById(this.uuid));
    const option = {
      tooltip: {
        trigger: 'axis', // 触发方式为坐标轴轴线触发
        // axisPointer: {
        //   type: 'cross' // 十字准星指示器
        // }
      },
      xAxis: {
        data: this.xData,
      },
      yAxis: {
        type:'value',
        show :false
      },
      grid:{
        left  : '10%',
        top:60
      },

      series: [
        {
          //   name: name,
          type: "line",
          stack: "总量",
          data: this.yData,
          smooth: true,
        },
      ],
    };
    this.myChart.setOption(option);
  },
};
</script>
   