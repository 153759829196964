<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <a-table :key="agent_key" :columns="columns" :data-source="agent_list" :pagination="false">
    </a-table>
    <a-pagination
      @change="agent_page_change"
      :total="agent_total"
      show-less-items
    />
  </div>
</template>
<script>
import { getAgent } from "@/api";
const columns = [
  {
    dataIndex: "id",
    key: "id",
    align: "center",
    title: "id",
  },
  {
    title: "用户id",
    dataIndex: "user_id",
    align: "center",
  },
  {
    title: "总收入",
    dataIndex: "price",
    align: "center",
  },
  {
    title: "现有余额",
    dataIndex: "surplus_price",//surplus_price
    align: "center",
  },
  {
    title: "总体现金额",
    dataIndex: "reflect_price",
    align: "center",
  },
];
export default {
  data() {
    return {
      columns,
      agent_list: [],
      agent_total: 0,
      agent_page: 1,
      agent_key:0
    };
  },
  methods: {
    agent_page_change(e) {
      this.agent_page = e;
      getAgent(this.agent_page).then((res) => {
        this.agent_list = res.data.data.data;
        this.agent_total = res.data.data.total;
        this.agent_key=this.agent_key+1
      });
    },
  },
  mounted() {
    getAgent(this.agent_page).then((res) => {
      this.agent_list = res.data.data.data;
      this.agent_total = res.data.data.total;
    });
  },
};
</script>