<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div class="top_header">
      <div class="serach_body">
        <a-input-search
          class="input_body"
          placeholder="输入信息"
          enter-button="搜索"
          @search="onSearch"
        />
      </div>
    </div>
    <a-table :columns="columns" :data-source="author_list" :pagination="false">
      <div slot="caozuo" slot-scope="record">
        <div v-if="record.status == 2">
          <a-button
            @click="edit_au(record.id)"
            size="small"
            type="primary"
            style="margin-right: 30px"
          >
            编辑
          </a-button>
          <a-popconfirm
          title="确定删除？"
          ok-text="Yes"
          cancel-text="No"
          @confirm="delet_au_item(record.id)"
          @cancel="delet_cancel"
        >
          <a-button
            @click="show_delet_modal(record.id)"
            size="small"
            type="danger"
          >
            删除
          </a-button>
        </a-popconfirm>
        </div>
        <div class="jvjue" v-if="record.status == 0">已拒绝</div>
        <div v-if="record.status == 1" class="caozuo">
          <a-button
            @click="success_au(record.id)"
            size="small"
            type="primary"
            style="margin-right: 30px"
          >
            通过
          </a-button>
          <a-button
              @click="failed_au(record.id)"
              size="small"
              type="danger"
            >
              拒绝
            </a-button>
            
        </div>
      </div>
    </a-table>
    <a-pagination
      @change="page_change"
      :total="total"
      show-less-items
    />

    <a-modal
      title="编辑"
      :visible="show_visible"
      @ok="edit_ok"
      @cancel="edit_cancel"
      :width="820"
    >
    <a-form-model :model="edit_items_body">
      <a-form-model-item label="输入作者名称">
        <a-input style="width: 300px" v-model="edit_items_body.author_name" />
      </a-form-model-item>
      <a-form-model-item label="输入作者提成">
        <a-input style="width: 300px" v-model="edit_items_body.ticheng" />
      </a-form-model-item>
      <a-form-model-item label="输入作者联系方式">
        <a-input style="width: 300px" v-model="edit_items_body.contact_num" />
      </a-form-model-item>
    </a-form-model>
    </a-modal>
    
  </div>
</template>
<script>
import { getAuthor, showAuthor, setAuthor,deleteAuthor } from "@/api";

const columns = [
  {
    dataIndex: "id",
    key: "id",
    align: "center",
    title: "id",
  },
  

  {
    title: "作者名称",
    align: "center",
    dataIndex: "author_name",
  },
  {
    title: "作者联系方式",
    align: "center",
    dataIndex: "contact_num",
  },
  {
    title: "现有额度",
    align: "center",
    dataIndex: "price_num",
  },
  {
    title: "体现额度",
    align: "center",
    dataIndex: "tixian_price",
  },
  {
    title: "提成",
    align: "center",
    dataIndex: "ticheng",
  },
  {
    title: "操作",
    align: "center",
    scopedSlots: { customRender: "caozuo" },
  },
];
export default {
  data() {
    return {
      columns,
      author_list: [],
      total:0,
      page:1,
      show_visible:false,
      edit_items_body:{}
    };
  },
  methods: {
    show_delet_modal(id){
    },
    delet_cancel(){},
    delet_au_item(id){
      console.log(id);
      deleteAuthor(id).then(res=>{
        getAuthor(this.page,'').then((res) => {
      this.author_list = res.data.data.data;
      this.total=res.data.data.total
      
    });

      })
    },
    edit_ok(){
      var id=this.edit_items_body.id
      setAuthor(this.edit_items_body, id).then((res) => {
        this.edit_items_body={}
        this.show_visible=false
        getAuthor(this.page,'').then((res) => {
      this.author_list = res.data.data.data;
      this.total=res.data.data.total
      
    });
      });
    },
    edit_cancel(){
      this.show_visible=false
    },
    edit_au(id){
      console.log(id);
      this.show_visible=true
      showAuthor(id).then(res=>{
        this.edit_items_body=res.data.data
      })
    },
    page_change(e){
      this.page = e;
    },
    onSearch(value){
      this.page = 1;
      getAuthor(this.page,value).then((res) => {
      this.author_list = res.data.data.data;
      this.total=res.data.data.total
      
    });
    },
    success_au(id) {
      const param = {
        status: 2,
      };
      setAuthor(param, id).then((res) => {
        console.log(res);
        getAuthor(this.page,'').then((res) => {
      this.author_list = res.data.data.data;
      this.total=res.data.data.total
      
    });
      });
    },
    failed_au(id) {
      const param = {
        status: 0,
      };
      setAuthor(param, id).then((res) => {
        console.log(res);
        getAuthor(this.page,'').then((res) => {
      this.author_list = res.data.data.data;
      this.total=res.data.data.total
      
    });
      });
    },
  },
  mounted() {
    getAuthor(this.page,'').then((res) => {
      this.author_list = res.data.data.data;
      this.total=res.data.data.total
      
    });
  },
};
</script>
<style scoped>
.serach_body {
  text-align: center;
  align-self: center;
  width: 300px;
  background-color: white;
  display: flex;
  direction: ltr;
  margin: 0 auto;
}
.top_header {
  background-color: #f0f2f5;
  width: 100%;
  height: 80px;
  direction: rtl;
  display: flex;
}
.jvjue{
    color: red;
}
</style>